import React from "react"
import { Col, Container, Row } from "reactstrap"
import TitleBlock from "../components/blocks/TitleBlock"
import FeatureBlock from "../components/blocks/FeatureBlock"

import baselineMeetingRoom from "@iconify/icons-ic/baseline-meeting-room"
import bedIcon from "@iconify/icons-cil/bed"
import showerIcon from "@iconify/icons-cil/shower"
import outlineKitchen from "@iconify/icons-ic/outline-kitchen"
import userFriends from "@iconify/icons-fa-solid/user-friends"
import hairDryer from "@iconify/icons-mdi/hair-dryer"
import tableChair from "@iconify/icons-mdi/table-chair"
import tvOutline from "@iconify/icons-ion/tv-outline"
import babyIcon from "@iconify/icons-fa-solid/baby"
import wifiIcon from "@iconify/icons-mdi/wifi"
import highchairIcon from "@iconify/icons-vs/highchair"
import baselineLocalParking from "@iconify/icons-ic/baseline-local-parking"
import bedSingleOutline from "@iconify/icons-mdi/bed-single-outline"
import filePaper2Line from "@iconify/icons-ri/file-paper-2-line"
import Photos from "../components/general/Photos"
import ContactInfo from "../components/general/ContactInfo"
import SmallHeader from "../components/SmallHeader"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout.en"
import SEO from "../components/seo"

const Aparttament4Osobowy = props => {
  const images = useStaticQuery(graphql`
    {
      other: allFile(
        filter: {
          relativeDirectory: { eq: "rooms/4-persons" }
          name: { ne: "cover" }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              sizes(maxWidth: 1800) {
                ...GatsbyImageSharpSizes_withWebp
              }
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      cover: file(
        name: { eq: "cover" }
        relativeDirectory: { eq: "rooms/4-persons" }
      ) {
        id
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <Layout>
        <SEO title="Standard eines Zimmers für 4 Personen" lang="de" />

        <SmallHeader
          title="Standard eines Zimmers für 4 Personen"
          backgroundName={images.cover}
          hasButtonStyle={true}
          price="360-430PLN / nacht"
        />

        <Container>
          <Row>
            <Col md="6" className="mr-auto ml-auto">
              <div className="text-center section">
                <TitleBlock
                  title="Standard eines Zimmers für 4 Personen"
                  headingLevel="h3"
                  description="All our rooms have a kitchenette, a bathroom and a balcony from which there is a beautiful view of the sea and the lighthouse on the upper floors. Below you will find a detailed list of facilities and equipment for the selected apartment."
                  extendedClasses="color-sea"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="7" className="mr-auto ml-auto">
              <div className="section">
                <Row>
                  <Col md="6">
                    <FeatureBlock icon={userFriends} text="4 Personen" />
                  </Col>
                  <Col md="6">
                    <FeatureBlock
                      icon={outlineKitchen}
                      text="Kochnische  (Induktionsplatte, Kühlschrank, Wasserkessel, Geschirr)"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FeatureBlock icon={baselineMeetingRoom} text="2 Zimmer" />
                  </Col>
                  <Col md="6">
                    <FeatureBlock
                      icon={showerIcon}
                      text="Badezimmer mit Duschkabine"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FeatureBlock
                      icon={bedIcon}
                      text="Schlafzimmer mit 2 Betten Typ Twin + 1 Schlafsofa für 2 Personen im Wohnzimmer"
                    />
                  </Col>
                  <Col md="6">
                    <FeatureBlock icon={hairDryer} text="Haartrockner" />
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FeatureBlock
                      icon={tableChair}
                      text="Balkon mit einem Balkontisch und -stühlen (ein Teil der Balkons in den oberen Stockwerken bietet eine Aussicht auf die Ostsee und den Leuchtturm"
                    />
                  </Col>
                  <Col md="6">
                    <FeatureBlock
                      icon={filePaper2Line}
                      text="Hand-/Badetücher für Gäste"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FeatureBlock
                      icon={tvOutline}
                      text="Fernseher (ca.130 Kanäle)"
                    />
                  </Col>
                  <Col md="6">
                    <FeatureBlock
                      icon={babyIcon}
                      text="Babybett (nach Buchung)"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FeatureBlock
                      icon={wifiIcon}
                      text="Internetverbindung (WiFi)"
                    />
                  </Col>
                  <Col md="6">
                    <FeatureBlock
                      icon={highchairIcon}
                      text="Babystuhl (nach Buchung)"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FeatureBlock
                      icon={baselineLocalParking}
                      text="Parkplatz (gegen zusätzliche Gebühr)"
                    />
                  </Col>
                  <Col md="6">
                    <FeatureBlock
                      icon={bedSingleOutline}
                      text="Zusatzbett (für Kinder über 4 Jahre gegen zusätzliche Gebühr)"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="10" className="mr-auto ml-auto">
              <div className="section">
                <Photos photos={images.other} />
              </div>

              <div className="section">
                <ContactInfo />
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Aparttament4Osobowy
